const config = {
  company: 'Schweerbau GmbH & Co. KG' || 'vib GmbH',
  logoURI:
    'https://utils.vib-development.ch/schweerbau/logo.svg' || 'https://utils.vib-development.ch/vib/logo.svg',
  logoWhiteURI:
    'https://utils.vib-development.ch/schweerbau/logo-white.svg' || 'https://utils.vib-development.ch/vib/logo-white.svg',

  address: 'Schweerbau GmbH & Co. KG,Industriestraße 12,31655 Stadthagen'?.split(',') || [
    'vib GmbH',
    'Grabenstrasse 12',
    '6340 Baar',
  ],
  contact: '+49 57 21 78 04-0,stadthagen@schweerbau.de'?.split(',') || [
    '+41 (0)41 763 16 94',
    'support@vib-gmbh.ch',
  ],
  apibaseuri:
    'https://api.main.schweerbau-connect-manual.ch4.amazee.io' ||
    'https://api.main.vib-connect-manual.ch4.amazee.io',
  appname: 'Schweerbau GmbH & Co. KG ECM Manual' || 'vib connect Manual',
  appdomain: 'nginx.main.schweerbau-connect-manual.ch4.amazee.io' || 'manual.vib-gmbh.ch',
  appurl: 'https://nginx.main.schweerbau-connect-manual.ch4.amazee.io' || 'https://manual.vib-gmbh.ch',
  statuspage: 'https://status.vib-development.ch' || 'https://status.vib-development.ch',
  support: 'support@vib-connect.de' || 'support@vib-gmbh.ch',
  root: '1JGeZYZ1BNthAjcKS352M3u6xr9T8ucGp' || '',
  footer: '1rNPt_ve9EaLnboFQHe8wIkpci9BFbE5v' || '',
  disclaimer: '1rcHoCE9sLHaw96TZQGO92Hdh7pv40rUY0VCAElE9fW4' || '',
  languages: 'DE'?.split(',') || ['DE','EN-GB'],
  screenBreak: '930' || 930
};

export default config;
